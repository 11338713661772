.testimonials__container {
    width: 30% !important;
    padding-bottom: 4rem !important;
    height: auto !important;
}

.client__avatar {
    width:4rem ;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);

}

.testimonial {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;

}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-slide {
    width: 100% !important;
    height: 100% !important;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 300px !important;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

/* MEDIA QUEIRES FOR MEDIUM DEVICES */

@media screen and (max-width: 1024px){
.testimonials__container{
        width: 60% !important;
        

    }

    #testimonials {
        margin-top: 50rem !important;
        padding-bottom: 2rem ;
    }
}

/* MEDIA QUERIES FOR SMALL DEVICES */
@media screen and (max-width: 600px) {
    .testimonials__container {
        width: 80% !important;
    }

    #testimonials {
        margin-top: 145rem !important;
        padding-bottom: 2rem ;
    }

    .client__review {
        width: var(--container-width-sm);
    }
}